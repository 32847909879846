<template>
  <div class='pan-details-main-wrapper'>
    <a-form>
      <a-row style='display: unset'>
        <a-col>
          <div style='margin-top: 100px'>
            <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
            <div>
              <div class='pan-details-title'>Let's start with your PAN</div>
              <div class='pan-details-stepper'>Step 1</div>

              <div class='pan-details-sub-title-description'>
                Your name will be taken as per ITD
              </div>
              <div class='pan-details-sub-title-description'>
                (Income Tax Department)
              </div>
              <div
                class='pan-details-input-btn-wrapper'
                style='margin-top: 30px'
              >
                <a-form-item v-bind='validateInfos.pan'>
                  <a-input
                    v-model:value='modelRef.pan'
                    placeholder='Enter PAN'
                    :maxlength=10
                    :disabled='disable'
                  >
                  </a-input>
                </a-form-item>
              </div>
              <div class='pan-details-input-btn-wrapper'>
                <!-- <a-form-item v-bind='validateInfos.dob'>
                  <a-date-picker
                    placeholder='Select DOB'
                    v-model:value='modelRef.dob'
                    :format='dateFormat'
                  ></a-date-picker>
                </a-form-item> -->
                <input style="    background: #fff;
                border-radius: 2px;
                border: 1px solid #79a89b;
                height: 44px;
                width: 317px;
                padding: 10px"
                class="pan-details-date-picker" @change="onChangeDate" type="date"  id="date"
                value=""
                :disabled='disable'>
              </div>
              <div style="text-align: initial;padding-left: 112px;font-size: 11px;">
                * DOB as per PAN</div>
              <div style="color:red;padding-left: 112px;text-align: initial;"
              v-if="dateIsBlank">Select your DOB</div>
              <div class='pan-details-checkbox-wrapper'>
                <a-checkbox
                  style='
                    text-align: initial;
                    font-size: 13px;
                    line-height: 15px;
                    font-weight: 400;
                    color: black;
                  '
                  v-model:input-value='checked'
                  @click='toggleChecked'
                  >I authorized castlegate to open my online account through
                  KRA/ CKYC/ Digilocker</a-checkbox
                >
              </div>
              <div class='pan-details-btn-wrapper'>
                <a-button type='primary' @click='onClickContinue' :loading="loading"
                  >Continue</a-button
                >
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
  <div v-if="showPopover">
    <a-modal
    title=""
    v-model:visible='visibleModal'
    :closable="true"
    footer=""
    destroyOnClose
    centered
    >
      <h3>Please keep a set of these documents ready for onboarding process -</h3>
      <p>1. Your PAN card,</p>
      <p>2. Your AADHAAR card,</p>
      <p>3. Your bank account details and a cancelled cheque,</p>
      <p>4. Your passport size photo (optional, you can upload your selfie),</p>
      <p>5. Your signature on a white paper, and</p>
      <p>6. Your bank account statement for last 6 months (optional,
        if you want to activate FNO for your account).</p>
      <p>*Max size limit for all the files & documents is 3MB,
        and bank account statement can be upto 10 MB in size.</p>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable */
// import { useRouter } from 'vue-router';
import { onMounted, ref, reactive } from 'vue';
import { Form } from 'ant-design-vue';
// import dayjs from 'dayjs';
import { useStore } from 'vuex';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';
import STAGES from '../../../constants/stages';
import getLogRocket from '../../../services/logrocket';

const { useForm } = Form;

export default {
  components: {},
  setup() {
    const store = useStore();
    const disable = ref(false);
    const loading = ref(false);
    const showPopover = ref(false);
    const visibleModal = ref(true);
    const showPopoverText = () => {
      showPopover.value = true;
    };
    const logrocket = getLogRocket();

    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          const { completedStages, prefill } = response.data.data;
          logrocket.setUser(prefill.mobile, {
            mobile: prefill.mobile,
            name: prefill.name,
            email: prefill.email,
            currentStage: response.data.data.stage,
          });
          const stage = completedStages.find((item) => item.stage === STAGES.STAGE_TYPE.PAN_VALIDATION);
          if (!stage && prefill.status === 'IN_PROGRESS') {
            showPopoverText();
          }
        })
        .catch(() => {
        });
    });

    // const router = useRouter();
    const checked = ref(false);
    const dateIsBlank = ref(false);
    const modelRef = reactive({
      pan: '',
      dob: '',
    });

    const rulesRef = reactive({
      pan: [
        {
          required: true,
          message: 'Enter PAN',
          min: 10,
          pattern: new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/),
        },
      ],
    });

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef,
    );
    const toggleChecked = () => {
      checked.value = !checked.value;
    };
    const initialData = ref('');
    const onChangeDate = () => {
      const selectedDate = document.getElementById('date').value;
      initialData.value = selectedDate;
      if (initialData.value) {
        dateIsBlank.value = false;
      } else {
        dateIsBlank.value = true;
      }
    };

    const onClickContinue = () => {
      validate().then(() => {
        if (initialData.value === '') {
          dateIsBlank.value = true;
          return;
        }
        if (!checked.value) {
          const message = 'Validation error';
          const description = 'Your consent is mandatory to fetch and verify your PAN data';
          Operations.notificationError(message, description);
          return;
        }
        // const selectedDate = dayjs(modelRef.dob).format('DD/MM/YYYY');
        // modelRef.dob = selectedDate;
        const panDetailsUppercase = modelRef.pan.toUpperCase();
        modelRef.pan = panDetailsUppercase;
        modelRef.dob = initialData.value;
        loading.value = true;
        disable.value = true;
        services
          .panAuthentication(modelRef)
          .then((response) => {
            loading.value = false;
            store.dispatch('onboardingStore/updateStage', response.data.data.stage);
            // router.push('/pan/uploaded');
            const { data } = response.data;
            store.dispatch('onboardingStore/updateClientName', data.prefill.name);
          })
          .catch((error) => {
            loading.value = false;
            disable.value = false;
            const systemError = Operations.axiosErrorHandler(error);
            const message = systemError.status;
            const description = systemError.message
              ? systemError.message
              : '';
            Operations.notificationError(message, description);
          });
      });
    };

    return {
      onClickContinue,
      checked,
      validateInfos,
      resetFields,
      modelRef,
      toggleChecked,
      onChangeDate,
      initialData,
      dateIsBlank,
      disable,
      loading,
      showPopover,
      visibleModal,
      showPopoverText,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/panDetails.scss';
</style>
